import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Login.vue";
import Dashboard from "../views/DashBoard.vue";
import ReportsPage from "../views/ReportsPage.vue";
import ManagementView from "../views/ManagementView.vue";
import SettingView from "../views/SettingView.vue";
import PaymentsView from "../views/PaymentsView.vue";
import WorkSpace from "../views/WorkSpace.vue";
import LeadDetail from "@/views/LeadDetail.vue";
import CreateProfile from "@/views/CreateProfile.vue";
import CampaginView from "@/views/CampaginView.vue";
import RecipientList from "@/views/RecipientList.vue";
import RecipientView from "@/views/RecipientView.vue";
import EmailTemplate from "@/views/EmailTemplate.vue";
import TestPayment from "@/views/TestPaymentView.vue";
import BookaDemoRequest from "@/views/BookaDemoRequest.vue";
import StringeeCall from "@/views/StringeeCall.vue";
import store from "../store";
import ClickToCall from "@/views/clickToCall.vue";

const routes = [
  {
    path: "/lead-ivr/calling",
    name: "ClickToCall",
    component: ClickToCall,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: Dashboard,
  },
  {
    path: "/new-profile",
    name: "newProfile",
    meta: { requiresAuth: true },
    component: CreateProfile,
  },
  {
    path: "/reports",
    name: "reports",
    meta: { requiresAuth: true },
    component: ReportsPage,
  },
  {
    path: "/management",
    name: "management",
    meta: { requiresAuth: true },
    component: ManagementView,
  },
  {
    path: "/campagin-dashboard/campagin",
    name: "campagin",
    meta: { requiresAuth: true },
    component: CampaginView,
  },
  {
    path: "/campagin-dashboard/recipient-list",
    name: "recipientList",
    meta: { requiresAuth: true },
    component: RecipientList,
  },
  {
    path: "/campagin-dashboard/recipient",
    name: "recipient",
    meta: { requiresAuth: true },
    component: RecipientView,
  },
  {
    path: "/campagin-dashboard/email-template",
    name: "emailTemplate",
    meta: { requiresAuth: true },
    component: EmailTemplate,
  },
  {
    path: "/book-a-demo",
    name: "demoRequest",
    meta: { requiresAuth: true },
    component: BookaDemoRequest,
  },
  {
    path: "/payments",
    name: "payments",
    meta: { requiresAuth: true },
    component: PaymentsView,
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true },
    component: SettingView,
  },
  {
    path: "/work_space",
    name: "workSpace",
    meta: { requiresAuth: true },
    component: WorkSpace,
  },
  {
    path: "/lead_detail/:id",
    name: "lead_detail",
    props: true,
    meta: { requiresAuth: true },
    component: LeadDetail,
  },
  {
    path: "/test-payment",
    name: "test_payment",
    component: TestPayment,
  },
  {
    path: "/stringee/",
    name: "stringee",
    component: StringeeCall,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
