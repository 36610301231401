<script setup>
import axios from "axios";
import { ref } from "vue";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";

import StringeePopup from "@/components/StringeePopup.vue";
import AnswerCall from "@/components/AnswerCall.vue";

const leadsDetails = ref([]);

function getIVRLeadInfo() {
  axios.get(`${process.env.VUE_APP_API}/api/list-callers/`).then((response) => {
    console.log(response);
    leadsDetails.value = response.data;
    console.log(leadsDetails, "this.leadsDetails");
  });
}
getIVRLeadInfo();
const showCallPopup = ref(false);
const selectedPhoneNumber = ref("");
// Modify your call button click handler
const handleCallClick = (phoneNumber) => {
  selectedPhoneNumber.value = phoneNumber;
  showCallPopup.value = true;
};

const message = ref("");
</script>
<template>
  <div>
    <div class="w-full md:h-[80px]">
      <TopBar />
    </div>
    <div class="flex">
      <div class="w-[60px] lg:w-[80px] h-full fixed top-0 z-20">
        <NavBar />
      </div>
      <div class="w-full pl-20">
        <h1 class="text-2xl font-semibold py-2">Lead List</h1>
        <div class="w-50">
          <AnswerCall />
        </div>

        <table class="w-full whitespace-nowrap bg-gray-100 mb-4 mx-auto">
          <thead class="text-white">
            <tr class="text-left">
              <th class="p-3 2xl:p-4 bg-[#2563EB] rounded-l-lg">S.no</th>
              <th class="p-3 2xl:p-4 bg-[#2563EB]">Name</th>
              <th class="p-3 2xl:p-4 bg-[#2563EB]">Phone</th>
              <th class="p-3 2xl:p-4 bg-[#2563EB]">Agent</th>
              <th class="xl:p-3 2xl:p-4 bg-[#2563EB] rounded-r-lg">Action</th>
            </tr>
          </thead>
          <tbody v-if="message">
            <tr class="text-left text-sm border-b">
              <td class="p-3 2xl:p-4">{{ message }}</td>
            </tr>
          </tbody>
          <tbody v-else class="p-0 overflow-y-auto">
            <tr
              v-for="(lead, index) in leadsDetails"
              :key="index"
              class="text-left text-sm border-b"
            >
              <td class="p-3 2xl:p-4 text-gray-700">
                {{ index + 1 }}
              </td>
              <td class="">
                <div v-if="lead.client_name" class="flex items-start gap-2">
                  <font-awesome-icon icon="user" class="text-blue-400" />
                  {{ lead.client_name }}
                </div>
                <div v-else>-</div>
              </td>
              <td class="p-3 2xl:p-4">
                <div v-if="lead.client_number" class="flex items-start gap-2">
                  <font-awesome-icon icon="envelope" class="text-blue-400" />
                  {{ lead.client_number }}
                </div>
                <div v-else>-</div>
              </td>

              <td class="p-3 2xl:p-4">
                <span v-if="lead.assignedAgent">{{
                  lead.assignedAgent.userid
                }}</span>
                <span v-else>-</span>
              </td>

              <td class="p-3 2xl:p-4 text-gray-700">
                <button
                  @click="handleCallClick(lead.client_number)"
                  class="cursor-pointer text-[#2563EB] hover:underline p-2 bg-green-700 rounded-md"
                >
                  <font-awesome-icon icon="phone" class="text-white text-sm" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- PopUp -->
      <!-- Add the popup component -->
      <StringeePopup
        v-if="showCallPopup"
        v-model:show="showCallPopup"
        :phone-number="selectedPhoneNumber"
      />
    </div>
  </div>
</template>

<style></style>
