<script setup>
import { ref, reactive } from "vue";
import { StringeeClient, StringeeCall } from "stringee";

// State management
const accessToken = ref("YOUR_ACCESS_TOKEN");
const fromNumber = ref("FROM_NUMBER");
// const copyFromNumber = ref("FROM_NUMBER");
const callTo = ref("");
const loggedUserId = ref("Not logged");
const callStatus = ref("Not started");
const callType = ref("...");
const isCallActive = ref(false);
const hasIncomingCall = ref(false);
const incomingCallFrom = ref("");
const authenticatedWithUserId = ref("");

// Refs for elements that need direct manipulation
const remoteVideo = ref(null);

// Global call data
const globalCallData = reactive({
  callType: null,
  fromNumber: null,
  toNumber: null,
});

// Client and call instances
let stringeeClient = null;
let currentCall = null;

// Client event setup
const settingClientEvents = (client) => {
  client.on("connect", () => {
    console.log("connected to StringeeServer");
  });

  client.on("authen", (res) => {
    console.log("on authen:", res);
    if (res.r === 0) {
      authenticatedWithUserId.value = res.userId;
      isCallActive.value = true;
      loggedUserId.value = res.userId;
    } else {
      loggedUserId.value = res.message;
    }
  });

  client.on("disconnect", () => {
    console.log("disconnected");
    isCallActive.value = false;
  });

  client.on("incomingcall", (incomingcall) => {
    currentCall = incomingcall;

    globalCallData.callType = incomingcall.isIncomingCall
      ? "internal"
      : "external";
    globalCallData.fromNumber = incomingcall.fromNumber;
    globalCallData.toNumber = incomingcall.toNumber;

    settingCallEvents(incomingcall);

    hasIncomingCall.value = true;
    incomingCallFrom.value = incomingcall.fromNumber;

    callType.value = incomingcall.fromInternal
      ? "App-to-App call"
      : "Phone-to-App call";
  });

  client.on("requestnewtoken", () => {
    console.log(
      "request new token; please get new access_token from YourServer"
    );
    // Implement token refresh logic here
  });

  client.on("otherdeviceauthen", (data) => {
    console.log("otherdeviceauthen:", data);
  });
};

// Call event setup
const settingCallEvents = (call1) => {
  call1.on("error", (info) => {
    console.log("on error:", JSON.stringify(info));
  });

  call1.on("addlocalstream", (stream) => {
    console.log("on addlocalstream", stream);
  });

  call1.on("addremotestream", (stream) => {
    console.log("on addremotestream", stream);
    if (remoteVideo.value) {
      remoteVideo.value.srcObject = null;
      remoteVideo.value.srcObject = stream;
    }
  });

  call1.on("signalingstate", async (state) => {
    console.log("signalingstate", state);
    callStatus.value = state.reason;

    if (state.code === 6) {
      // call ended
      hasIncomingCall.value = false;
      isCallActive.value = false;
      callStopped();
    }

    if (state.code === 5) {
      // busy here
      isCallActive.value = false;
      callStopped();
    }

    // Call Started
    if (
      state.reason === "Answered" &&
      state.code === 3 &&
      state.sipCode === 200 &&
      state.sipReason === "OK"
    ) {
      await updateCallLog(true);
    }

    // Call Over
    if (
      state.reason === "Ended" &&
      state.code === 6 &&
      state.sipCode === -1 &&
      state.sipReason === "Bye"
    ) {
      await updateCallLog(false);
    }
  });

  call1.on("mediastate", async (state) => {
    console.log("mediastate", state);
    if (state.reason === "Connected" && state.code === 1) {
      await updateCallLog(true);
    }
  });

  call1.on("otherdevice", (data) => {
    console.log("on otherdevice:", JSON.stringify(data));
    if (
      (data.type === "CALL_STATE" && data.code >= 200) ||
      data.type === "CALL_END"
    ) {
      hasIncomingCall.value = false;
    }
  });
};

// Call management functions
const login = () => {
  loggedUserId.value = "Connecting...";

  if (!stringeeClient) {
    stringeeClient = new StringeeClient();
    settingClientEvents(stringeeClient);
    stringeeClient.connect(accessToken.value);
  }
};

const makeCall = () => {
  if (!callTo.value) return;

  const callFrom = fromNumber.value || authenticatedWithUserId.value;
  currentCall = new StringeeCall(stringeeClient, callFrom, callTo.value);

  settingCallEvents(currentCall);

  currentCall.makeCall((res) => {
    console.log("make call callback:", JSON.stringify(res));

    globalCallData.callType = res.toType;
    globalCallData.fromNumber = res.fromNumber;
    globalCallData.toNumber = res.toNumber;

    if (res.r !== 0) {
      callStatus.value = res.message;
    } else {
      callType.value =
        res.toType === "internal" ? "App-to-App call" : "App-to-Phone call";
      // Set call state to indicate an active call
      isCallActive.value = true;
    }
  });
};
const answerCall = () => {
  if (currentCall) {
    currentCall.answer((res) => {
      console.log("answer res", res);
      hasIncomingCall.value = false;
    });
  }
};

const rejectCall = () => {
  callStopped();
  if (currentCall) {
    currentCall.reject((res) => {
      console.log("reject res", res);
      hasIncomingCall.value = false;
    });
  }
};

// const hangupCall = async () => {
//   if (currentCall) {
//     if (remoteVideo.value) {
//       remoteVideo.value.srcObject = null;
//     }

//     callStopped();

//     currentCall.hangup((res) => {
//       console.log("hangup res", res);
//     });

//     await updateCallLog(false);
//   }
// };

const hangupCall = async () => {
  if (currentCall) {
    if (remoteVideo.value) {
      remoteVideo.value.srcObject = null;
    }

    callStopped();

    currentCall.hangup((res) => {
      console.log("hangup res", res);
      // Reset call state after hanging up
      isCallActive.value = false;
      currentCall = null;
    });

    await updateCallLog(false);
  }
};

const callStopped = () => {
  isCallActive.value = false;

  setTimeout(() => {
    callStatus.value = "Call ended";
  }, 1500);
};

// API calls
const updateCallLog = async (answering) => {
  try {
    // Check if required data is present
    if (!globalCallData.fromNumber || !globalCallData.toNumber) {
      console.warn("Missing call data, skipping call log update");
      return;
    }

    const response = await fetch("http://127.0.0.1:8000/answer_url/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from_number: globalCallData.fromNumber,
        to_number: globalCallData.toNumber,
        from_external:
          globalCallData.callType === "external" ? "False" : "True",
        answering: answering ? "True" : "False",
      }),
    });

    if (!response.ok) {
      // Log more detailed error information
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const data = await response.json();
    console.log("CallLog updated successfully", data);
  } catch (error) {
    console.error("Error updating CallLog:", error);

    // Optional: Add more specific error handling
    if (error instanceof TypeError) {
      console.error("Network error or fetch failed");
    } else if (error instanceof SyntaxError) {
      console.error("Response was not valid JSON");
    }
  }
};

// Load Stringee SDK
// onMounted(() => {
//   const script = document.createElement("script");
//   script.src = "https://cdn.stringee.com/sdk/1.5.20/stringee.js";
//   script.async = true;
//   script.onload = () => {
//     console.log("Stringee SDK loaded successfully");
//   };
//   document.body.appendChild(script);
// });
</script>

<template>
  <div
    class="stringee-call-container bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen flex items-center justify-center p-6 bg-slate-600"
  >
    <div
      class="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden transform transition-all duration-300 hover:scale-105"
    >
      <!-- Login Section -->
      <div class="p-6 bg-gradient-to-r from-blue-500 to-indigo-600">
        <h2 class="text-2xl font-bold text-white mb-4">
          Stringee Call Interface
        </h2>
        <textarea
          v-model="accessToken"
          rows="4"
          class="w-full rounded-lg p-3 mb-4 bg-white/20 text-white placeholder-white/70 focus:outline-none focus:ring-2 focus:ring-white/50 transition-all duration-300"
          placeholder="Enter your access token"
        />
        <div class="flex justify-between items-center">
          <button
            @click="login"
            :disabled="
              loggedUserId !== 'Not logged' && loggedUserId !== 'Connecting...'
            "
            class="px-6 py-2 bg-white text-blue-600 rounded-full font-semibold hover:bg-white/90 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Login
          </button>
          <span class="text-white/80">
            Logged in:
            <span
              :class="{
                'text-green-300 font-bold': loggedUserId !== 'Not logged',
              }"
            >
              {{ loggedUserId }}
            </span>
          </span>
        </div>
      </div>

      <!-- Call Controls -->
      <div v-if="loggedUserId !== 'Not logged'" class="p-6">
        <div class="mb-4">
          <label class="block text-gray-700 font-semibold mb-2"
            >Make call from:</label
          >
          <input
            v-model="fromNumber"
            type="text"
            placeholder="Enter phone number"
            class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-semibold mb-2"
            >Make call to:</label
          >
          <div class="flex space-x-3">
            <input
              v-model="callTo"
              type="text"
              placeholder="userId or number"
              class="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
            />
            <button
              @click="makeCall"
              class="px-5 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-300"
            >
              Call
            </button>
            <button
              @click="hangupCall"
              class="px-5 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300"
            >
              Hangup
            </button>
          </div>
        </div>

        <!-- Incoming Call Section -->
        <div
          v-if="hasIncomingCall"
          class="bg-yellow-50 border-l-4 border-yellow-500 p-4 rounded-lg mb-4"
        >
          <div class="flex justify-between items-center">
            <span class="text-yellow-700"
              >Incoming call from: {{ incomingCallFrom }}</span
            >
            <div class="space-x-2">
              <button
                @click="answerCall"
                class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-300"
              >
                Answer
              </button>
              <button
                @click="rejectCall"
                class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300"
              >
                Reject
              </button>
            </div>
          </div>
        </div>

        <!-- Call Status -->
        <div
          class="bg-gray-100 rounded-lg p-3 flex justify-between items-center"
        >
          <div>
            <span class="font-semibold text-gray-700">Call Status:</span>
            <span class="text-blue-600">{{ callStatus }}</span>
          </div>
          <div>
            <span class="font-semibold text-gray-700">Call Type:</span>
            <span class="text-purple-600">{{ callType }}</span>
          </div>
        </div>
      </div>

      <!-- Video Container -->
      <div class="video-container p-6">
        <video
          ref="remoteVideo"
          playsinline
          autoplay
          class="w-full rounded-xl shadow-lg bg-black"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
