<script setup>
import { ref, reactive, onMounted, defineProps } from "vue";

const accessToken = ref(
  "eyJhbGciOiJIUzI1NiIsImN0eSI6InN0cmluZ2VlLWFwaTt2PTEiLCJ0eXAiOiJKV1QifQ.eyJqdGkiOiJTSy4yLmh1eXdZbHdicjZ1ZFJDQ2k5NEdrSGxYakNmSVVaeVJCLTE3MzQ1ODgwMDQiLCJpc3MiOiJTSy4yLmh1eXdZbHdicjZ1ZFJDQ2k5NEdrSGxYakNmSVVaeVJCIiwiZXhwIjoxNzM3MTgwMDA0LCJ1c2VySWQiOiJtYWhlIn0.tW5AK8gkVLbql1k2_zFF_yqHC8RyNT41_5QywOWRUmc"
);

const loggedUserId = ref("Not logged");
const callStatus = ref("Not started");
const callType = ref("...");
const isCallActive = ref(false);
const isCallActiveButton = ref(false);

const hasIncomingCall = ref(false);
const incomingCallFrom = ref("");
const authenticatedWithUserId = ref("");
const isSDKLoaded = ref(false);

const remoteVideo = ref(null);

const globalCallData = reactive({
  callType: null,
  fromNumber: null,
  toNumber: null,
});

let stringeeClient = null;
let currentCall = null;

const loadStringeeSDK = () => {
  return new Promise((resolve, reject) => {
    if (window.StringeeClient) {
      isSDKLoaded.value = true;
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src =
      "https://cdn.stringee.com/sdk/web/latest/stringee-web-sdk.min.js";
    script.async = true;

    script.onload = () => {
      console.log("Stringee SDK loaded successfully");
      isSDKLoaded.value = true;
      if (window.StringeeUtil) {
        console.log(
          "StringeeUtil.isWebRTCSupported: " +
            window.StringeeUtil.isWebRTCSupported()
        );
      }
      resolve();
    };

    script.onerror = (error) => {
      console.error("Failed to load Stringee SDK:", error);
      reject(error);
    };

    document.body.appendChild(script);
  });
};

const settingClientEvents = (client) => {
  client.on("connect", () => {
    console.log("connected to StringeeServer");
  });

  client.on("authen", (res) => {
    console.log("on authen:", res);
    if (res.r === 0) {
      authenticatedWithUserId.value = res.userId;
      isCallActive.value = true;
      loggedUserId.value = res.userId;
      // Added color change on successful auth
      if (document.getElementById("loggedUserId")) {
        document.getElementById("loggedUserId").style.color = "blue";
      }
    } else {
      loggedUserId.value = res.message;
    }
  });

  client.on("disconnect", () => {
    console.log("disconnected");
    isCallActive.value = false;
  });

  client.on("incomingcall", (incomingcall) => {
    currentCall = incomingcall;

    globalCallData.callType = incomingcall.isIncomingCall
      ? "internal"
      : "external";
    globalCallData.fromNumber = incomingcall.fromNumber;
    globalCallData.toNumber = incomingcall.toNumber;

    settingCallEvents(incomingcall);

    hasIncomingCall.value = true;
    incomingCallFrom.value = incomingcall.fromNumber;

    callType.value = incomingcall.fromInternal
      ? "App-to-App call"
      : "Phone-to-App call";
  });

  client.on("requestnewtoken", () => {
    console.log(
      "request new token; please get new access_token from YourServer and call client.connect(new_access_token)"
    );
  });

  client.on("otherdeviceauthen", (data) => {
    console.log("otherdeviceauthen:", data);
  });
};

const settingCallEvents = (call1) => {
  isCallActive.value = true;

  call1.on("error", (info) => {
    console.log("on error:", JSON.stringify(info));
  });

  call1.on("addlocalstream", (stream) => {
    console.log("on addlocalstream", stream);
  });

  call1.on("addremotestream", (stream) => {
    console.log("on addremotestream", stream);
    if (remoteVideo.value) {
      remoteVideo.value.srcObject = null;
      remoteVideo.value.srcObject = stream;
    }
  });

  call1.on("signalingstate", async (state) => {
    console.log("signalingstate", state);
    callStatus.value = state.reason;

    if (state.code === 6) {
      hasIncomingCall.value = false;
      isCallActive.value = false;
      callStopped();
    }

    if (state.code === 5) {
      isCallActive.value = false;
      callStopped();
    }

    if (
      state.reason === "Answered" &&
      state.code === 3 &&
      state.sipCode === 200 &&
      state.sipReason === "OK"
    ) {
      await updateCallLog(true);
    }

    if (
      state.reason === "Ended" &&
      state.code === 6 &&
      state.sipCode === -1 &&
      state.sipReason === "Bye"
    ) {
      await updateCallLog(false);
    }
  });

  call1.on("mediastate", async (state) => {
    console.log("mediastate", state);
    if (state.reason === "Connected" && state.code === 1) {
      await updateCallLog(true);
    }
  });

  // Added missing info and otherdevice event handlers
  call1.on("info", (info) => {
    console.log("on info", info);
  });

  call1.on("otherdevice", (data) => {
    console.log("on otherdevice:", JSON.stringify(data));
    if (
      (data.type === "CALL_STATE" && data.code >= 200) ||
      data.type === "CALL_END"
    ) {
      hasIncomingCall.value = false;
    }
  });
};

const login = async () => {
  try {
    if (!isSDKLoaded.value) {
      await loadStringeeSDK();
    }

    loggedUserId.value = "Connecting...";
    console.log("accessToken...:", accessToken.value);

    if (!stringeeClient && window.StringeeClient) {
      stringeeClient = new window.StringeeClient();
      settingClientEvents(stringeeClient);
      stringeeClient.connect(accessToken.value);
    }
  } catch (error) {
    console.error("Failed to initialize Stringee:", error);
    loggedUserId.value = "Failed to load SDK";
  }
};

const answerCall = () => {
  if (currentCall) {
    currentCall.answer((res) => {
      console.log("answer res", res);
      hasIncomingCall.value = false;
      isCallActiveButton.value = true;
    });
  }
};

const rejectCall = () => {
  callStopped();
  if (currentCall) {
    currentCall.reject((res) => {
      console.log("reject res", res);
      hasIncomingCall.value = false;
    });
  }
};

const hangupCall = async () => {
  if (currentCall) {
    if (remoteVideo.value) {
      remoteVideo.value.srcObject = null;
    }

    callStopped();

    currentCall.hangup((res) => {
      console.log("hangup res", res);
      isCallActive.value = false;
      currentCall = null;
    });

    await updateCallLog(false);
  }
};

const callStopped = () => {
  isCallActive.value = false;
  setTimeout(() => {
    callStatus.value = "Call ended";
    isCallActiveButton.value = false;
  }, 1500);
};

const updateCallLog = async (answering) => {
  try {
    if (!globalCallData.fromNumber || !globalCallData.toNumber) {
      console.warn("Missing call data, skipping call log update");
      return;
    }

    const response = await fetch(`${process.env.VUE_APP_API}/answer_url/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from_number: globalCallData.fromNumber,
        to_number: globalCallData.toNumber,
        from_external:
          globalCallData.callType === "external" ? "False" : "True",
        answering: answering ? "True" : "False",
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const data = await response.json();
    console.log("CallLog updated successfully", data);
  } catch (error) {
    console.error("Error updating CallLog:", error);
  }
};

// Initialize on component mount
onMounted(async () => {
  try {
    await login();
  } catch (error) {
    console.error("Failed to initialize Stringee:", error);
  }
});

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  phoneNumber: {
    type: String,
    default: "",
  },
});

// const emit = defineEmits(["close", "update:show"]);
</script>

<template>
  <div class="bg-white rounded-lg p-6 max-w-md w-full relative">
    <div class="space-y-4">
      <div></div>
      <div class="flex justify-center space-x-4"></div>
      <video
        ref="remoteVideo"
        playsinline
        autoplay
        class="w-full h-48 bg-black rounded hidden"
      />
    </div>

    <div
      v-if="isCallActiveButton"
      class="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-3 w-[60%]"
    >
      <div class="relative">
        <svg
          class="w-8 h-8 text-blue-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
          />
        </svg>
        <div
          class="absolute inset-0 animate-ping bg-blue-400 rounded-full opacity-20"
        ></div>
      </div>
      <span class="font-medium">{{ incomingCallFrom }}</span>

      <button
        @click="hangupCall"
        class="p-2 bg-red-500 rounded-full text-white hover:bg-red-600"
      >
        <svg
          class="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <!-- incoming call -->
    <div v-if="hasIncomingCall">
      <div
        class="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-3 w-[63%]"
      >
        <!-- Ringing icon with animation -->
        <div class="relative">
          <svg
            class="w-8 h-8 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <div
            class="absolute inset-0 animate-ping bg-blue-400 rounded-full opacity-20"
          ></div>
        </div>

        <span class="font-medium">{{ incomingCallFrom }}</span>

        <div class="flex space-x-2">
          <button
            @click="answerCall"
            class="p-2 bg-green-500 rounded-full text-white hover:bg-green-600"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </button>
          <button
            @click="rejectCall"
            class="p-2 bg-red-500 rounded-full text-white hover:bg-red-600"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- end of incoming call -->
  </div>
</template>
